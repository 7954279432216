<template>
  <v-form>
    <v-text-field
      :label="$helpers.string.capitalizeI18N('first-name', { required: true })"
      v-model="user.firstName"
      prepend-icon="mdi-organization"
      type="text"
    />

    <v-text-field
      :label="$helpers.string.capitalizeI18N('last-name', { required: true })"
      v-model="user.lastName"
      prepend-icon="mdi-organization"
      type="text"
    />

    <v-text-field
      :label="$helpers.string.capitalizeI18N('email') + '*'"
      v-model="user.email"
      name="email"
      prepend-icon="mdi-email"
      type="email"
    />
  </v-form>
</template>

<script>
export default {
  name: "Step1",
  components: {
  },
  props: {
    user: Object,
  },
  data() {
    return {};
  },
};
</script>