import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"4"}},[_c(VCard,[_c(VStepper,{model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c(VStepperHeader,[_c(VStepperStep,{attrs:{"complete":_vm.e1 > 1,"step":"1"}},[_vm._v("User details")]),_c(VDivider),_c(VStepperStep,{attrs:{"complete":_vm.e1 > 2,"step":"2"}},[_vm._v("Organization")]),_c(VDivider),_c(VStepperStep,{attrs:{"step":"3"}},[_vm._v("Site")])],1),_c(VStepperItems,[_c(VStepperContent,{attrs:{"step":"1"}},[_c('Step1',{attrs:{"user":_vm.user}}),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.e1 = 2}}},[_vm._v("Continue")])],1),_c(VStepperContent,{attrs:{"step":"2"}},[_c('Step2',{attrs:{"organization":_vm.organization}}),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.e1 = 3}}},[_vm._v("Continue")]),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.e1 = 1}}},[_vm._v("Previous")])],1),_c(VStepperContent,{attrs:{"step":"3"}},[_c('Step3',{attrs:{"site":_vm.site}}),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.e1 = 2}}},[_vm._v("Previous")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.validate}},[_vm._v("Validate")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }