<template>
  <v-form>
    <v-text-field
      :label="$helpers.string.capitalizeI18N('name', { required: true })"
      v-model="organization.name"
      prepend-icon="mdi-organization"
      type="text"
    />

    <v-text-field
      :label="$helpers.string.capitalizeI18N('phone-number', { required: true })"
      v-model="organization.phoneNumber"
      prepend-icon="mdi-phone"
      type="text"
    />

    <v-text-field
      :label="$helpers.string.capitalizeI18N('website', { required: true })"
      v-model="organization.website"
      prepend-icon="mdi-web"
      type="text"
    />

  </v-form>
</template>

<script>
export default {
  name: "Step1",
  props: {
      organization: Object
  },
  data() {
    return {
      
    };
  },
};
</script>