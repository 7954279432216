<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">User details</v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2">Organization</v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">Site</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <Step1 v-bind:user="user" />

                <v-btn color="primary" @click="e1 = 2">Continue</v-btn>
              </v-stepper-content>

              <v-stepper-content step="2">
                <Step2 v-bind:organization="organization" />

                <v-btn color="primary" @click="e1 = 3">Continue</v-btn>

                <v-btn text @click="e1 = 1">Previous</v-btn>
              </v-stepper-content>

              <v-stepper-content step="3">
                <Step3 v-bind:site="site" />

                <v-btn text @click="e1 = 2">Previous</v-btn>

                <v-btn color="primary" @click="validate">Validate</v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Step1 from "@/components/Admin/FirstLogin/Step1";
import Step2 from "@/components/Admin/FirstLogin/Step2";
import Step3 from "@/components/Admin/FirstLogin/Step3";
import Address from '@/models/address'

export default {
  name: "FirstLogin",
  components: {
    Step1,
    Step2,
    Step3,
  },
  data() {
    return {
      e1: 1,
      organization: {
        name: "",
        phoneNumber: "",
        website: "",
      },
      site: {
        name: "",
        phoneNumber: "",
        website: "",
      },
      user: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        website: '',
        address: new Address()
      }
    };
  },

  methods: {
    validate() {
      const payload = {
        organization: this.organization,
        user: this.user,
        site: this.site,
      };
      
      this.$store.dispatch('adminauth/firstLogin', payload);
    }
  },
  created() {
    const currentOrganization = this.$store.state.organization.organization;
    
    if (currentOrganization != null) {
      this.organization.name = currentOrganization.name;
      this.organization.phoneNumber = currentOrganization.phoneNumber;
      this.organization.website = currentOrganization.website;
    }

    // const currentSite = null;
    
    // if (currentSite != null) {
    //   this.site.name = currentSite.name;
    //   this.site.phoneNumber = currentSite.phoneNumber;
    //   this.site.website = currentSite.website;
    // }

    const currentUser = this.$store.state.adminauth.user.user;
    
    if (currentUser != null) {
      this.user.firstName = currentUser.firstName;
      this.user.lastName = currentUser.lastName;
      this.user.email = currentUser.email;
    }
  },
};
</script>